<template>
	<div class="system-content">
		<div class="system-left" :style="height">
			<p class="title">索赔管理</p>
			<el-menu :default-active="defaultActivePath" router>
				<el-menu-item
					v-for="(item,index) in menuList"
					:index="item.path"
					:key="index">
					<i :class="item.icon"></i>
					<span slot="title">{{item.title}}</span>
				</el-menu-item>
			</el-menu>
		</div>
		<div class="system-right" :class="className">
			<transition name="fade-transform" mode="out-in">
				<router-view :key="key"/>
			</transition>
		</div>
	</div>
</template>
<script>
export default {
	data() {
		return {
			perms: JSON.parse(window.sessionStorage.getItem('perms')),
			defaultActivePath: '',
			height: '',
			className: 'p1',
			menuList: []
		}
	},
	computed: {
        key() {
            return this.$route.path
		}
	},
	created() {
		this.init();
		// 处理路由重复点击报错问题
		this.handleRepeatRoute();
	},
	watch: {
		$route(from,to) {
			this.init();
			this.handleRepeatRoute();
		}
	},
	methods: {
		init() {
			this.height = 'min-height:' + (window.innerHeight - 180) + 'px;';
			let menuArray = [];
            if(this.perms.includes('home:repair:model')) {
                menuArray.push(
                    {
                        title: '维修机型',
                        icon: 'el-icon-receiving',
                        name: 'repairModel',
                        path: '/claimantManagement/repairModel'
                    }
                );
            }
            if(this.perms.includes('home:repair:apply')) {
                menuArray.push(
                    {
                        title: '维修申请',
                        icon: 'el-icon-tickets',
                        name: 'repairRequest',
                        path: '/claimantManagement/repairRequest'
                    }
                );
            }
            if(this.perms.includes('home:repair:approval')) {
                menuArray.push(
                    {
                        title: '维修审批',
                        icon: 'el-icon-document-checked',
                        name: 'repairApproval',
                        path: '/claimantManagement/repairApproval'
                    }
                );
            }
            if(this.perms.includes('home:repair:logisticsApproval')) {
                menuArray.push(
                    {
                        title: '物流审批',
                        icon: 'el-icon-wind-power',
                        name: 'logisticsApproval',
                        path: '/claimantManagement/logisticsApproval'
                    }
                );
            }
            if(this.perms.includes('home:repair:claimsLogistics')) {
                menuArray.push(
                    {
                        title: '索赔物流',
                        icon: 'el-icon-truck',
                        name: 'claimantLogistics',
                        path: '/claimantManagement/claimantLogistics'
                    }
                );
            }
            if(this.perms.includes('home:repair:expenseApproval')) {
                menuArray.push(
                    {
                        title: '费用审批',
                        icon: 'el-icon-money',
                        name: 'expenseApproval',
                        path: '/claimantManagement/expenseApproval'
                    }
                );
            }
            if(this.perms.includes('home:repair:returnVisit')) {
                menuArray.push(
                    {
                        title: '回访',
                        icon: 'el-icon-service',
                        name: 'followUp',
                        path: '/claimantManagement/followUp'
                    }
                );
            }

			if(this.perms.includes('home:repair:extendedWarrantyRecord')) {
				menuArray.push(
					{
						title: '延保记录',
						icon: 'el-icon-document',
						name: 'extendedWarrantyRecord',
						path: '/claimantManagement/extendedWarrantyRecord'
					}
				);
			}

			if(this.perms.includes('home:repair:shippingAddress')) {
				menuArray.push(
					{
						title: '送货地址',
						icon: 'el-icon-map-location',
						name: 'repairAdvance',
						path: '/claimantManagement/repairAdvance'
					}
				);
			}

			if(this.perms.includes('home:repair:settlement')) {
				menuArray.push(
					{
						title: '结算信息',
						icon: 'el-icon-bank-card',
						name: 'repairSettle',
						path: '/claimantManagement/repairSettle'
					}
				);
			}

			if(this.perms.includes('home:repair:replacedDeviceNumber')) {
				menuArray.push(
					{
						title: '已换机机号',
						icon: 'el-icon-monitor',
						name: 'repairExchange',
						path: '/claimantManagement/repairExchange'
					}
				);
			}

			this.menuList = menuArray;
			// 刷新页面不自动跳转回左侧第一个菜单
			if(/claimantManagement/.test(this.$route.path) && this.$route.name != 'claimantManagement') {
				return
			}
			// 进入页面自动加载左侧第一个菜单
			if(menuArray.length > 0) {
				if(menuArray[0].children) {
					this.$router.replace({
						path: menuArray[0].children[0].path
					})
				}else {
					this.$router.replace({
						path: menuArray[0].path
					})
				}
			}
		},
		// 处理路由重复点击报错问题
		handleRepeatRoute() {
            this.className = 'p1';
			this.defaultActivePath = this.$route.path;
            if (this.$route.name === 'repairRequestDetail') {
                const menuAction = this.$route.query.menuAction;
                for (const menu of this.menuList) {
                    if (menu.name === menuAction) {
                        this.defaultActivePath = menu.path;
                        break;
                    }
                }
            }
            if (this.$route.name === 'repairRequestAddEdit') this.defaultActivePath = '/claimantManagement/repairRequest';
		}
	}
}
</script>
<style>
	.p1{
		padding: 15px;
	}
	.p2{
		padding: 0;
	}
</style>
<style lang="scss" scoped>
.system-content{
	display: flex;
	box-sizing: border-box;
	padding-top: 20px;
	.system-left{
		width: 166px;
        min-width: 166px;
        max-width: 166px;
		background-color: #fff;
		border-radius: 10px;
		box-sizing: border-box;
		padding-top: 25px;
		padding-right: 15px;
		min-height: 520px;
		max-height: 730px;
		.title{
			height: 30px;
			font-size: 24px;
			margin-bottom: 10px;
			text-align: center;
		}
		.el-menu{
			border-right: none;
			border-radius: 10px;
			.el-menu-item{
				border-top-right-radius: 26px;
				border-bottom-right-radius: 26px;
			}
			.el-menu-item.is-active{
				background-color: #ecf5ff;
			}
		}
	}
	.system-right{
		flex: 1;
		background-color: #fff;
		border-radius: 10px;
		margin-left: 10px;
		box-sizing: border-box;
        max-width: calc(100% - 166px);
	}
}
.el-menu-item{
	padding-left: 0;
	i{
		width: 20px;
		height: 20px;
		display: inline-block;
		margin-right: 10px;
	}
	.ricoh-icon-user{
		width: 22px;
		height: 22px;
		background: url('~@/assets/images/yonghu.png') no-repeat;
		background-size: 19px 19px;
	}
}

.el-menu-item.is-active{
	.ricoh-icon-user{
		width: 22px;
		height: 22px;
		background: url('~@/assets/images/yonghu-1.png') no-repeat;
		background-size: 19px 19px;
	}
}
</style>
